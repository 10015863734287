<template>
  <div>
    <p class="subtitle">Tendances</p>
    <div class="roller">
      <party v-for="idd in parties" :key="idd.id" :party="idd"></party>
    </div>
  </div>
</template>

<script>
import Party from "@/components/Party";
export default {
  name: "Acceuil",
  props: ['api', 'user'],
  components: {Party},
  data () {
    return {
      parties: []
    }
  },
  mounted() {
    this.api.getParties().then(res => {
      this.parties = res
    })
  }
}
</script>

<style scoped>
.roller {
  overflow: scroll;
  background-color: #f2f4f5;
}
p.subtitle {
  padding-top: 15px;
  color: rgba(209, 35, 42, .5);
  display: block;
  max-width: 85vh;
  padding-left: calc((100vw - 90vw) / 2);
  padding-bottom: 10px;
}
</style>
