<template>
  <div>
    <form action="#">
      <div>
        <label for="title">Nom de l'évènement</label>
        <input type="text" required="" id="title" placeholder="Jurrra meet up">
      </div>
      <div>
        <label for="date">Date de l'évènement</label>
        <input type="date" required="" id="date">
      </div>
      <div>
        <label for="hour">Heure d'ouverture de l'évènement</label>
        <input type="time" id="hour">
      </div>
      <div>
        <label for="place">Emplacement de l'évènement</label>
        <input type="text" required="" id="place" placeholder="Delémont">
      </div>
      <div>
        <label for="route">Emplacement de l'évènement (google)</label>
        <input type="text" id="route" placeholder="https://google.ch/...">
      </div>
      <div>
        <label for="url">Site internet de l'évènement</label>
        <input type="text" id="url" placeholder="https://jura.ch">
      </div>
      <div>
        <label for="price">Prix de l'évènement</label>
        <input type="number" id="price" placeholder="50">
      </div>
      <div>
        <button type="submit">Publier</button>
      </div>
      <div>
        <button type="reset">Effacer</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Create"
}
</script>

<style scoped>
form {
  max-width: 80vw;
  margin: auto;
  padding: 20px 0 50px 0;
}
form div {
  padding: 10px 0;
}
form div label {
  color: rgba(209, 35, 42, .7);
  font-size: 18px;
}
label, input, button {
  display: block;
  width: 100%;
}
form div input {
  font-size: 18px;
  padding: 8px;
  border: none;
  border-radius: 10px;
  width: calc(100% - 16px);
}
form div input::placeholder {
  color: rgba(209, 35, 42, .4);
}
form div input:focus {
  box-shadow: 0  0 10px #ccc;
  border: none;
  outline: none;
}
button[type="submit"] {
  color: #fff;
  background-color: rgba(209, 35, 42, 1);
  border: none;
  border-radius: 10px;
  padding: 10px;
}

button[type="reset"] {
  color: rgba(209, 35, 42, 1);
  background-color: transparent;
  border: none;
  border-radius: 10px;
  padding: 10px;
}
</style>
