<template>
  <div>
    <div class="header">
      <p class="name">{{ user.firstname + " " + user.name }}</p>
      <ul>
        <li><a href="#"><i class="fas fa-cog"></i></a></li>
      </ul>
    </div>
    <div class="roller">
      <p class="subtitle">Mes prochains événements</p>
      <party v-for="idd in parties" :key="idd.id" :party="idd"></party>
    </div>
  </div>
</template>

<script>
import Party from "@/components/Party";
export default {
  name: "Me",
  props: ['user', 'api'],
  components: { Party },
  data() {
    return {
      parties: []
    }
  },
  mounted() {
    this.api.getFavParties().then(res => {
      this.parties = res
    })
  }
}
</script>

<style scoped>
.name {
  font-size: 30px;
  color: rgba(209, 35, 42, .5);
  padding: calc((100vw - 90vw) / 2);
  margin-bottom: 10px;
}
.subtitle {
  font-size: 18px;
  color: rgba(209, 35, 42, .5);
  padding: calc((100vw - 90vw) / 2);
  margin-bottom: 5px;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header ul {
  display: flex;
  flex-direction: row;
  padding-right: calc((100vw - 90vw) / 2);

}
.header ul li a {
  padding: 7px;
  border-radius: 100%;
  box-shadow: 0  0 10px #ccc;
  display: flex;
  text-align: center;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  color: rgba(209, 35, 42, 1);
}
</style>
