<template>
  <div id="app">
    <div id="body">
      <transition name="component-fade" mode="out-in">
        <component v-bind:is="page" :api="api" :user="user"></component>
      </transition>
    </div>
    <nav>
      <ul>
        <li><a :class="{'actual' : page === 'home'}" href="#" @click="page = 'home'"><i class="fas fa-home"></i></a></li><!-- Proposition populaire -->
        <li><a :class="{'actual' : page === 'search'}" href="#" @click="page = 'search'"><i class="fas fa-search-plus"></i></a></li><!-- Carte + choix des régions -->
        <li><a :class="{'actual' : page === 'create'}" href="#" @click="page = 'create'"><i class="fas fa-plus"></i></a></li><!-- Un événement -->
        <li><a :class="{'actual' : page === 'me'}" href="#" @click="page = 'me'"><i class="fas fa-user-alt"></i></a></li><!-- Mes evenement, Mon compte, paramètres -->
      </ul>
    </nav>
  </div>
</template>

<script>
import Project from './components/Project'
import Acceuil from "@/components/Home";
import Search from "@/components/Search";
import Create from "@/components/Create";
import Me from "@/components/Me";
export default {
  name: 'App',
  data () {
    return {
      user: {},
      api: new Project("https://foody.ch"),
      page: "home"
    }
  },
  components: {
    home: Acceuil,
    search: Search,
    create: Create,
    me: Me
  },
  mounted() {
    this.api.getUser(1).then(res => {
      this.user = res
    })
  }
}
</script>

<style>
* {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
}
#body {
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: #f2f4f5;
  overflow: scroll;
}
nav {
  position: fixed;
  bottom: 0;
  display: block;
  width: 100vw;
  background-color: #f2f4f5;
  z-index: 3000;
}
nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 300px;
  margin: auto;
}
nav ul li a {
  padding: 15px;
  display: block;
  color: rgba(209, 35, 42, .5);
}
nav ul li a.actual {
  color: rgba(209, 35, 42, 1);
}
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
