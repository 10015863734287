export default class Project {
    constructor (url) {
        this.url = url
    }

    async getUser(userid) {
        if (userid === 1){ userid = 2}
        return {
            id: 1,
            name: "Rogora",
            firstname: "Laura",
            type: "user",
            lasttime: Date.now() - 60*60
        }
    }

    async getParties(area=null) {
        if (area != null) {
            area = 1
        }
        return [
            {
                id: 1,
                name: "Braderie prévotoise",
                date: Date.now(),
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [47.427684, 7.060305]
            },
            {
                id: 2,
                name: "Braderie prévotoise",
                date: Date.now(),
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [ 47.246457, 7.006285]
            },
            {
                id: 3,
                name: "Braderie prévotoise",
                date: Date.now(),
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [47.342857, 7.421019]
            },
            {
                id: 4,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [ 47.346794, 7.360273 ]
            },
            {
                id: 5,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [47.358328, 7.161909]
            },
            {
                id: 6,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [ 47.364273, 7.130978 ]
            },
            {
                id: 7,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [ 47.367855, 7.056026 ]
            },
            {
                id: 8,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [ 47.373552, 7.055085 ]
            },
            {
                id: 9,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [ 47.393054, 6.939877 ]
            },
            {
                id: 10,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: false,
                marker: [ 47.394348, 7.247316 ]
            },
            {
                id: 11,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: true,
                marker: [ 47.269815, 7.363324 ]
            },
            {
                id: 12,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: true,
                marker: [ 47.266899, 6.988212 ]
            }
        ]

    }

    async getFavParties() {
        return [
            {
                id: 1,
                name: "Braderie prévotoise",
                date: Date.now(),
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: true,
                marker: [47.427684, 7.060305]
            },
            {
                id: 11,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: true,
                marker: [47.246457, 7.006285]
            },
            {
                id: 12,
                name: "Braderie prévotoise",
                date: Date.now() + 60*60*24*1000,
                localisation: "https://www.google.com/maps/dir//2740+Moutier/@47.2768497,7.3173655,13z/data=!4m17!1m7!3m6!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2s2740+Moutier!3b1!8m2!3d47.2782749!4d7.3716656!4m8!1m0!1m5!1m1!1s0x4791e0a155653411:0x7ec2fefb22e04f39!2m2!1d7.3716656!2d47.2782749!3e3",
                humanlocalisation: "2740 Moutier",
                url: "https://braderie-prevotoise.ch/",
                fav: true,
                marker: [47.342857, 7.421019]
            }
        ]

    }
}
