<template>
  <div style="height: calc(100vh - 51px)">
    <nav>

    </nav>
    <l-map
        style="height: 100%; width: 100%"
        :zoom="zoom"
        :center="center"
        :min-zoom="9"
    >
      <l-marker v-for="party in parties" :key="party.id" :lat-lng="party.marker" @click="showParty(party.id)"></l-marker>
      <l-tile-layer :url="url"></l-tile-layer>
    </l-map>
    <div class="main">
      <a href="#" @click="showed = false" v-show="showed && !singleShowed"><i class="fas fa-chevron-down"></i></a>
      <a href="#" @click="showed = true" v-show="!showed && !singleShowed"><i class="fas fa-chevron-up"></i></a>
      <transition name="slide-fade">
        <div v-show="singleShowed" class="singleparty">
          <a href="#" @click="singleShowed = false"><i class="fas fa-chevron-down"></i></a>
          <party :party="singleShowedParty"></party>
        </div>
      </transition>
      <transition name="slide-fade">
        <div v-show="!singleShowed && showed" class="multipleparty">
          <party v-for="party in parties" :key="party.id" :party="party" :wrappable="true" :wrapped="true"></party>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet'
import Party from "@/components/Party";
export default {
  name: "Search",
  props: ['api', 'user'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    Party
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 9,
      center: [47.122030, 7.179445],
      bounds: null,
      parties: [],
      singleShowedParty: {},
      singleShowed: false,
      height: 45,
      showed: true
    };
  },
  methods: {
    showParty(id) {
      this.singleShowedParty = this.parties.filter(party => party.id === id)[0]
      this.singleShowed = true
    }
  },
  mounted() {
    this.api.getParties().then(res => {
      this.parties = res
    })
  }
}
</script>

<style scoped>
.main {
  position: fixed;
  bottom: 50px;
  z-index: 2000;
  max-height: 41vh;
  width: 100%;
}
.singleparty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  align-items: center;
}
.leaflet-control-container {
  display: none;
}
svg {
  padding: 10px;
}
a {
  color: #333333;
  display: block;
  text-align: center;
  width: 100%;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
.multipleparty {
  overflow: scroll;
  width: 100vw;
  align-items: center;
  max-height: calc(41vh - 41px);
}
</style>
