<template>
  <div class="party">
    <div class="header">
      <p class="title">{{ party.name }}</p>
      <ul>
        <li class="website"><a :href="party.url" target="_blank"><i class="fas fa-info"></i></a></li>
        <li class="route"><a :href="party.localisation" target="_blank"><i class="fas fa-route"></i></a></li>
        <li class="tofav">
          <a @click="putToSaved" href="#">
            <span :class="{'hidden': !party.fav}">
              <i class="fas fa-heart"></i>
            </span>
            <span :class="{'hidden': party.fav}">
              <i class="far fa-heart"></i>
            </span>
          </a>
        </li>
      </ul>
    </div>
    <ul class="info" v-show="!wrapped">
      <li><i class="far fa-clock"></i>{{ toHumanDate(party.date) }}</li>
      <li><i class="fas fa-map-marker-alt"></i>{{ party.humanlocalisation }}</li>
      <li><i class="fas fa-dollar-sign"></i>50.-</li>
    </ul>
    <button v-show="wrappable && wrapped"  @click="wrapped = false" class="wrapper"><i class="fas fa-angle-double-down"></i></button>
    <button v-show="wrappable && !wrapped" @click="wrapped = true" class="wrapper"><i class="fas fa-angle-double-up"></i></button>
  </div>
</template>

<script>
export default {
  name: "Party",
  props: ['party', 'wrappable', 'wrapped'],
  methods: {
    toDay(id) {
      switch(id) {
        case 1: return "Lun";
        case 2: return "Mar";
        case 3: return "Mer";
        case 4: return "Jeu";
        case 5: return "Ven";
        case 6: return "Sam";
        case 7: return "Dim";
      }
    },
    toMonth(id) {
      switch(id) {
        case 1: return "janvier";
        case 2: return "février";
        case 3: return "mars";
        case 4: return "avril";
        case 5: return "mai";
        case 6: return "juin";
        case 7: return "juillet";
        case 8: return "août";
        case 9: return "septembre";
        case 10: return "octobre";
        case 11: return "novembre";
        case 12: return "décembre";
      }
    },
    toHumanDate(timestamp) {
      let date = new Date(timestamp)
      return this.toDay(date.getDay()) + ", " + date.getDate() + " " + this.toMonth(date.getMonth() + 1) + " " + date.getFullYear() + " à " + date.getHours() + ":" + date.getMinutes()
    },
    putToSaved() {
      this.party.fav = !this.party.fav
    }
  }
}
</script>

<style scoped>
.party {
  background-color: #fff;
  padding: 10px;
  max-width: 85vw;
  border-radius: 10px;
  margin: 0 auto 20px auto;
  box-shadow: 0  0 10px #ccc;
}
.party .title {
  font-size: 24px;
  padding: 10px;
}
.party ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.party .header {
  display: flex;
  flex-direction: row;
  padding-bottom: 17px
}

.party ul li a {
  padding: 7px;
  border-radius: 100%;
  box-shadow: 0  0 10px #ccc;
  display: flex;
  text-align: center;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  color: rgba(209, 35, 42, 1);
}
.party ul.info {
  display: flex;
  flex-direction: column;
}
.party ul.info li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.party ul.info svg {
  padding: 10px;
}
.hidden {
  display: none;
}
.wrapper {
  font-size: 18px;
  width: 100%;
  margin: -4px 0 0 0;
  background-color: transparent;
  border: none;
  padding: 5px 0 10px 0;
}
</style>
